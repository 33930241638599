import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'

import loggedin from '@/views/loggedin'

import ApiService from '@/ApiService';

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        redirect: i18n.locale
    },
    {
        path: '/:locale',
        redirect: { name: 'public_home', params: { locale: i18n.locale } }
    },
    {
        path: '/apprs',
        name: 'apprs',
        component: () => import('@/views/appLogin')
    },
    {
        path: '/applogout',
        name: 'applogout',
        component: () => import('@/views/appLogout')
    },
    {
        path: '/:locale/menu',
        name: 'loggedin',
        component: loggedin,
        children: [
            {
                path: 'Home',
                name: 'loggedinHome',
                component: () => import('@/views/loggedinHome')
            },
            {
                path: 'Code/Index',
                name: 'lstCode',
                component: () => import('@/views/lstCode')
            },
            {
                path: 'Unit/Index',
                name: 'lstUnit',
                component: () => import('@/views/lstUnit')
            },
            {
                path: 'Unit/add',
                name: 'insUnit',
                component: () => import('@/views/insUnit')
            },
            {
                path: 'Unit/Rec',
                name: 'recUnit',
                component: () => import('@/views/recUnit')
            },
            {
                path: 'User/Index',
                name: 'lstUser',
                component: () => import('@/views/lstUser')
            },
            {
                path: 'User/add',
                name: 'insUser',
                component: () => import('@/views/insUser')
            },
            {
                path: 'User/Rec',
                name: 'recUser',
                component: () => import('@/views/recUser')
            },
            {
                path: 'User',
                name: 'insUserCurrent',
                component: () => import('@/views/insUserCurrent')
            },
            {
                path: 'Role/Index',
                name: 'lstRole',
                component: () => import('@/views/lstRole')
            },
            {
                path: 'Role/add',
                name: 'insRole',
                component: () => import('@/views/insRole')
            },
            {
                path: 'Role/Rec',
                name: 'recRole',
                component: () => import('@/views/recRole')
            },
            {
                path: 'Privilege/Index',
                name: 'lstPrivilege',
                component: () => import('@/views/lstPrivilege')
            },
            {
                path: 'Privilege/add',
                name: 'insPrivilege',
                component: () => import('@/views/insPrivilege')
            },
            {
                path: 'Privilege/Rec',
                name: 'recPrivilege',
                component: () => import('@/views/recPrivilege')
            },
            {
                path: 'Menu/Index',
                name: 'lstMenu',
                component: () => import('@/views/lstMenu')
            },
            {
                path: 'Menu/add',
                name: 'insMenu',
                component: () => import('@/views/insMenu')
            },
            {
                path: 'Menu/Rec',
                name: 'recMenu',
                component: () => import('@/views/recMenu')
            },
            {
                path: 'UserRole/Index',
                name: 'lstUserRole',
                component: () => import('@/views/lstUserRole')
            },
            {
                path: 'UserRole/add',
                name: 'insUserRole',
                component: () => import('@/views/insUserRole')
            },
            {
                path: 'UserRole/Rec',
                name: 'recUserRole',
                component: () => import('@/views/recUserRole')
            },
            {
                path: 'Code/Update/:id',
                name: 'updCode',
                component: () => import('@/views/insCode')
            },
            {
                path: 'Code/Add',
                name: 'insCode',
                component: () => import('@/views/insCode')
            },
            {
                path: 'Code/Rec',
                name: 'recCode',
                component: () => import('@/views/recCode')
            },
            {
                path: 'Codedetail/Index',
                name: 'lstCodeDetail',
                component: () => import('@/views/lstCodeDetail')
            },
            {
                path: 'Codedetail/Update/:id',
                name: 'updCodeDetail',
                component: () => import('@/views/insCodeDetail')
            },
            {
                path: 'Codedetail/Add',
                name: 'insCodeDetail',
                component: () => import('@/views/insCodeDetail')
            },
            {
                path: 'Codedetail/Rec',
                name: 'recCodeDetail',
                component: () => import('@/views/recCodeDetail')
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import('@/views/privacy')
            },
            {
                path: 'ChooseRole',
                name: 'lstChooseRole',
                component: () => import('@/views/lstChooseRole')
            },
            {
                path: 'SysParameter/Index',
                name: 'lstSysParameter',
                component: () => import('@/views/lstSysParameter')
            },
            {
                path: 'SysParameter/Add',
                name: 'insSysParameter',
                component: () => import('@/views/insSysParameter')
            },
            {
                path: 'SysParameter/Update',
                name: 'updSysParameter',
                component: () => import('@/views/insSysParameter')
            },
            {
                path: 'SysParameter/Rec',
                name: 'recSysParameter',
                component: () => import('@/views/recSysParameter')
            },
            {
                path: 'Login/Index',
                name: 'lstLogin',
                component: () => import('@/views/lstLogin')
            },
            {
                path: 'Login/Add',
                name: 'insLogin',
                component: () => import('@/views/insLogin')
            },
            {
                path: 'Login/Update',
                name: 'updLogin',
                component: () => import('@/views/insLogin')
            },
            {
                path: 'Login/Rec',
                name: 'recLogin',
                component: () => import('@/views/recLogin')
            },
            {
                path: 'Report/Sample',
                name: 'rptSample',
                component: () => import('@/views/rptSample')
            },
            {
                path: 'Course/Index',
                name: 'lstCourse',
                component: () => import('@/views/lstCourse')
            },
            {
                path: 'Course/Add',
                name: 'insCourse',
                component: () => import('@/views/insCourse')
            },
            {
                path: 'Course/Update',
                name: 'updCourse',
                component: () => import('@/views/insCourse')
            },
            {
                path: 'Course/Rec',
                name: 'recCourse',
                component: () => import('@/views/recCourse')
            },
            {
                path: 'DeviceTables/Index',
                name: 'lstDeviceTables',
                component: () => import('@/views/lstDeviceTables')
            },
            {
                path: 'DeviceTables/Add',
                name: 'insDeviceTables',
                component: () => import('@/views/insDeviceTables')
            },
            {
                path: 'DeviceTables/Rec',
                name: 'recDeviceTables',
                component: () => import('@/views/recDeviceTables')
            },
            {
                path: 'DeviceInventory/Index',
                name: 'lstDeviceInventory',
                component: () => import('@/views/lstDeviceInventory')
            },
            {
                path: 'DeviceInventory/Add',
                name: 'insDeviceInventory',
                component: () => import('@/views/insDeviceInventory')
            },
            {
                path: 'DeviceInventory/Rec',
                name: 'recDeviceInventory',
                component: () => import('@/views/recDeviceInventory')
            },
            {
                path: 'DeviceInventory/Update',
                name: 'updDeviceInventory',
                component: () => import('@/views/insDeviceInventory')
            },
            {
                path: 'GoldenSampleFeature/Index',
                name: 'lstGoldenSampleFeature',
                component: () => import('@/views/lstGoldenSampleFeature')
            },
      
            {
                path: 'Controller/Upload',
                name: 'IOTUpload',
                component: () => import('@/views/lstIOTUpload')
            },
            {
                path: 'Controller/SensorImpedance',
                name: 'lstSensorImpedance',
                component: () => import('@/views/lstSensorImpedance')
            },
            {
                path: 'GoldenSampleFeature/Add',
                name: 'insGoldenSampleFeature',
                component: () => import('@/views/insGoldenSampleFeature')
            },
            {
                path: 'GoldenSampleFeature/Update',
                name: 'updGoldenSampleFeature',
                component: () => import('@/views/insGoldenSampleFeature')
            },
            {
                path: 'GoldenSampleFeature/Rec',
                name: 'recGoldenSampleFeature',
                component: () => import('@/views/recGoldenSampleFeature')
            },
            {
                path: 'Activity/Index',
                name: 'lstActivity',
                component: () => import('@/views/lstActivity')
            },
            {
                path: 'Activity/Add',
                name: 'insActivity',
                component: () => import('@/views/insActivity')
            },
            {
                path: 'Activity/Update',
                name: 'updActivity',
                component: () => import('@/views/insActivity')
            },
            {
                path: 'Activity/Rec',
                name: 'recActivity',
                component: () => import('@/views/recActivity')
            },
            {
                path: 'Mission/Add',
                name: 'insMission',
                component: () => import('@/views/insMission')
            },
            {
                path: 'Mission/Update',
                name: 'updMission',
                component: () => import('@/views/insMission')
            },
            {
                path: 'Mission/Index',
                name: 'lstMission',
                component: () => import('@/views/lstMission')
            },
            {
                path: 'Mission/Rec',
                name: 'recMission',
                component: () => import('@/views/recMission')
            },
            {
                path: 'Report',
                name: 'report',
                component: () => import('@/views/report')
            },
            {
                path: 'Report/asdReport',
                name: 'asdReport',
                component: () => import('@/views/asdReport')
            },
            {
                path: 'Report/adhdReport',
                name: 'adhdReport',
                component: () => import('@/views/adhdReport')
            },
         
            {
                path: 'Report/concussionReport',
                name: 'concussionReport',
                component: () => import('@/views/concussionReport')
            },
            {
                path: 'Class/Index',
                name: 'lstClass',
                component: () => import('@/views/lstClass')
            },
            {
                path: 'Class/add',
                name: 'insClass',
                component: () => import('@/views/insClass')
            },
            {
                path: 'Class/Rec',
                name: 'recClass',
                component: () => import('@/views/recClass')
            },
            {
                path: 'ProjectParticipant/Index',
                name: 'lstProjectParticipant',
                component: () => import('@/views/lstProjectParticipant')
            },
            {
                path: 'ProjectParticipant/add',
                name: 'insProjectParticipant',
                component: () => import('@/views/insProjectParticipant')
            },
            {
                path: 'ProjectParticipant/Rec',
                name: 'recProjectParticipant',
                component: () => import('@/views/recProjectParticipant')
            },
            {
                path: 'ActivityParticipant/Index',
                name: 'lstActivityParticipant',
                component: () => import('@/views/lstActivityParticipant')
            },
            {
                path: 'ActivityParticipant/add',
                name: 'insActivityParticipant',
                component: () => import('@/views/insActivityParticipant')
            },
            {
                path: 'ActivityParticipant/Rec',
                name: 'recActivityParticipant',
                component: () => import('@/views/recActivityParticipant')
            },
            {
                path: 'ActivityLog/Index',
                name: 'lstActivityLog',
                component: () => import('@/views/lstActivityLog')
            },
            {
                path: 'ActivityLog/Add',
                name: 'insActivityLog',
                component: () => import('@/views/insActivityLog')
            },
            {
                path: 'ActivityLog/Rec',
                name: 'recActivityLog',
                component: () => import('@/views/recActivityLog')
            },
            {
                path: 'ActivityParticipant/Add/Multi',
                name: 'insActivityParticipantMulti',
                component: () => import('@/views/insActivityParticipantMulti')
            },
            {
                path: 'MotionData/Add/Multi',
                name: 'lstMotionData',
                component: () => import('@/views/lstMotionData')
            },
            {
                path: 'MotionData/Add',
                name: 'insMotionData',
                component: () => import('@/views/insMotionData')
            },
            {
                path: 'MotionData/Rec',
                name: 'recMotionData',
                component: () => import('@/views/recMotionData')
            },
            {
                path: 'setIotType',
                name: 'setIotType',
                component: () => import('@/components/common/cmpIotType')
            },
            {
                path: 'Tenant/CloudWatchLog',
                name: 'TenantCloudWatchLog',
                component: () => import('@/views/tenant/TenantCloudWatchLog')
            },
            {
                path: 'Tenant/BackupRestore',
                name: 'TenantBackupRestore',
                component: () => import('@/views/tenant/TenantBackupRestore')
            },
            {
                path: 'Tenant/Site/Add',
                name: 'TenantSite',
                component: () => import('@/views/tenant/TenantSite')
            },
            {
                path: 'Tenant/Course/Add',
                name: 'TenantCourse',
                component: () => import('@/views/tenant/TenantCourse')
            },
            {
                path: 'Tenant/Menu/Add',
                name: 'TenantMenu',
                component: () => import('@/views/tenant/TenantMenu')
            },
            {
                path: 'Tenant/Controller/Add',
                name: 'TenantController',
                component: () => import('@/views/tenant/TenantController')
            },
            {
                path: 'Tenant/Code/Add',
                name: 'TenantCode',
                component: () => import('@/views/tenant/TenantCode')
            }
        ]
    },
    {
        path: '/:locale',
        component: {
            render: h => h('router-view')
        },
        children: [
            {
                path: 'public',
                name: 'public',
                component: () => import('@/views/public/index'),
                children: [
                    {
                        path: 'home',
                        name: 'public_home',
                        component: () => import('@/views/public/home')
                    },
                    {
                        path: 'news',
                        name: 'public_news',
                        component: () => import('@/views/public/news')
                    },
                    {
                        path: 'contactus',
                        name: 'public_contactus',
                        component: () => import('@/views/public/contactus')
                    }
                ]
            }
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    routes: routes
})


router.beforeEach(async (to, from, next) => {

    if (!from.params.locale && to.params.locale) {
        i18n.locale = to.params.locale;
    }

    if (to.query.TK) {
        //Vue.cookie.set('token', to.query.TK);
        localStorage.setItem('token', to.query.TK);

        if (!localStorage.getItem('profile')) {
            const profileApi = new ApiService('profile');
            var profile = await profileApi.getProfile();

            if (profile && profile.length > 0) {
                localStorage.setItem('profile', JSON.stringify(profile[0]));
            }
        }
    }

    if (to.params.locale === from.params.locale) {
        next()
        return;
    }

    next();
})

export default router
