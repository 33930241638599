<!-- 登入後 session timeout 前跳出的畫面，讓使用者決定要不要延長 session
    有兩個 timer:
    - login timer: 計時結束後會顯示 popup
    - countdown timer: 顯示在 popup 還剩多少時間 token 會 expire
-->
<template>
    <b-modal id="myModal">
        <template #modal-title>
            {{ $t('message.extendSession') }}?
        </template>
        <div class="d-block text-center">
            {{ timerMinutes }} {{ $t('form.minute') }} {{ timerSeconds}} {{ $t('form.second') }}
        </div>
        <template #modal-footer>
            <button type="button" class="btn btn-secondary" @click.prevent="submit()">{{ $t('form.yes') }}</button>
            <button type="button" class="btn btn-secondary" @click.prevent="cancel()">{{ $t('form.no') }}</button>
        </template>
    </b-modal>
</template>

<script>
    import ApiService from '@/ApiService';

    export default {
        data() {
            return {
                timerMinutes: 0,    // bind 到倒數的分鐘
                timerSeconds: 0,    // bind 到倒數的秒數
                countDownTimerHandle: null
            };
        },
        methods: {
            /*
             *  Setup 這個 timer
             */
            setupTimer() {
                //let expirationTime = this.$root.$data.store.getLocalStorage("tokenExpiration");
                //let remainingTime = expirationTime - Date.now() - 1800000; // Expire 30 分鐘前
                //setTimeout(function () { this.showTimerPopup() }.bind(this), remainingTime);

                this.startPopupTimer();
            },
            /*
             *  每 5 秒檢查看要不要顯式 popup
             */
            startPopupTimer() {
                setTimeout(() => {
                    let remainingTime = this.getTokenRemainingTime() - 1800000; // Expire 30 分鐘前

                    if (remainingTime <= 0) {
                        this.showTimerPopup();
                    }
                    else {
                        this.startPopupTimer();
                    }
                }, 5000)

            },
            /*
             *  顯示 popup
             */
            show() {
                this.$bvModal.show('myModal')
            },
            /*
             *  更新 token
             */
            async submit() {
                await this.renewToken();
                this.$emit("submit");
            },
            /*
             *  取消並隱藏 timer
             */
            cancel() {
                if (this.countDownTimerHandle) {
                    clearInterval(this.countDownTimerHandle);
                }
                this.$bvModal.hide('myModal')
            },
            /*
             *  開始 countdown timer 的倒數
             */
            startCountdownTimer() {
                let countDown = this.getTokenRemainingTime();

                if (countDown > 0) {
                    this.countDownTimerHandle = setTimeout(() => {

                        // Time calculations for days, hours, minutes and seconds
                        //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        //var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        this.timerMinutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
                        this.timerSeconds = Math.floor((countDown % (1000 * 60)) / 1000);

                        // Token timeout 後直接登出
                        if (countDown <= 0) {
                            const routerName = "public_home";
                            this.$root.$data.store.logout();
                            this.$router.push({ name: routerName })
                        }
                        else {
                            this.startCountdownTimer();
                        }
                    }, 1000)
                }
                else {
                    const routerName = "public_home";
                    this.$root.$data.store.logout();
                    this.$router.push({ name: routerName })
                }
            },
            /*
             *  更新 token 和重新設定 timer
             */
            async renewToken() {
                const apiLogin = new ApiService('authenticate');
                await apiLogin.renewToken();
                this.setupTimer();
                this.cancel();
            },
            /*
             *  顯示 timer 和開始倒數
             */
            showTimerPopup() {
                let countDownInterval = this.getTokenRemainingTime();
                this.show();
                this.startCountdownTimer(countDownInterval);
            },
            getTokenRemainingTime() {
                let expirationTime = this.$root.$data.store.getLocalStorage("tokenExpiration");
                return expirationTime - Date.now();
            }

        }
    }

</script>