<!-- 登入畫面後上面 menu 的 component -->
<template>
    <div id="top">
        <div id="logo"><router-link :to="getHomeRouterLink()"><img :src="require(`@/assets/images/${siteId}/Logo.png`)" /></router-link></div>
        <div id="logomb"><router-link :to="getHomeRouterLink()"><img :src="require(`@/assets/images/${siteId}/Logo.png`)" /></router-link></div>
        <div id="search">
            <table border="0" cellspacing="2" cellpadding="2">
                <tr>
                    <td width="160"><input type="search" size="24" v-bind:placeholder="$t('form.searchTerm')"></td>
                    <td width="543" align="left"><img src="@/assets/icons/IcnSearchButton.png"></td>
                </tr>

                <tr>
                    <td colspan="2">
                        <div class="marqee">
                            <ul>
                                <li>{{marqee}}</li>
                            </ul>
                        </div>
                    </td>
                </tr>

            </table>
        </div>
        <div id="info">




            <div class="tooltip">
                <a href="#" @click.prevent="logout()"><img src="@/assets/icons/IcnLogout.png" width="45"></a>
                <span class="tooltiptext">{{ $t('form.logout') }}</span>
            </div>

            <div class="tooltip">
                <a href="#"><img src="@/assets/icons/IcnLang.png" width="45"></a>
                <span class="tooltiptext">
                    <ul class="ul-no-style">
                        <li class="pointer" @click.prevent="changeLocale('en-us')">en-us</li>
                        <li class="pointer" @click.prevent="changeLocale('zh-tw')">zh-tw</li>
                        <li class="pointer" @click.prevent="changeLocale('zh-cn')">zh-cn</li>
                    </ul>
                </span>
            </div>

            <div class="tooltip">
                <router-link :to="getHomeRouterLink()"><img src="@/assets/icons/IcnHome.png" width="45"></router-link>
                <span class="tooltiptext">{{ $t('form.home') }}</span>
            </div>

            <div class="tooltipname">{{userName}} {{projectRoleName}}<span id="compno">{{siteId}}</span></div>
        </div>

        <div id="menu" style="width:100%">
            <ul>
                <div v-for="child in nav" :key="child.id" class="menubox">
                    <cmpNavItem v-bind:nav="child"></cmpNavItem>
                </div>
            </ul>
        </div>

        <div id="openmbmenu">
            <a href="#" @click.prevent="logout()"><img src="@/assets/icons/IcnLogout.png"></a>
            <a href="#" @click.prevent="changeLocale()"><img src="@/assets/icons/IcnLang.png"></a>
            <a href="fs_index.html"><img src="@/assets/icons/IcnHome.png"></a>
            <a href="#"><img src="@/assets/icons/IcnMbMenu.png" id="mbmenu_icon" v-on:click="openMbMenu()"></a>
            <div style="text-align:right">{{userName}} {{projectRoleName}}<span id="compno">{{siteId}}</span></div>
        </div>

        <div id="mbmenu">
            <span class="white_linktext">
                <div id="closembmenu"><img src="@/assets/icons/IcnMbMenuClose.png" id="mbmenuclose_icon"  v-on:click="closeMbMenu()"></div>
                <ul>
                    <div v-for="child in nav" :key="child.id" class="menubox">
                        <cmpNavItemMB v-bind:nav="child" @closeMenu="closeMbMenu"></cmpNavItemMB>
                    </div>
                </ul>
            </span>
        </div>

    </div>
</template>

<style>
    .tooltip {
        position: relative;
        opacity: 1;
        display: inline-block
    }
</style>
<script>
    import i18n from '@/i18n'

    import cmpNavItem from '@/components/common/cmpNavItem';
    import cmpNavItemMB from '@/components/common/cmpNavItemMB';
    import ApiService from '@/ApiService';

    export default {
        data() {
            return {
                userName: '',           // 顯示在上面的使用者的名字
                siteId: '',             // SiteId
                projectRoleName: '',    // 顯示在上面的使用者的 project role
                marqee: '',             // bind 到跑馬燈
                userId: '',             // 使用者的 UserId，用來檢查登入的資料有沒有改變
                SelectedRole: ''        // 使用者選取的 role，用來檢查登入的資料有沒有改變
            };
        },
        props: {
            nav: Array, // 要顯示的 Menu
        },
        components: {
            cmpNavItem,
            cmpNavItemMB
        },
        async created() {
            let profile = this.$root.$data.store.getProfile();
            this.userName = profile['Name'];
            this.siteId = profile['SiteId'];
            this.projectRoleName = profile['ProjectRoleName'];
            this.userId = profile['UserId'];
            this.selectedRole = profile['SelectedRole'];

            // Load 跑馬燈
            // const apiSys = new ApiService("SysParameter");
            // const res = await apiSys.getAll({ "ParametervarName": "MessageBoard" });

            // if (res["NumberOfRecords"] == 1) {
            //     if (res["Data"][0]["ParametervarValue"]) {
            //         this.marqee = res["Data"][0]["ParametervarValue"];
            //     }
            // }
        },
        watch: {
            '$route.fullPath': 'checkSession'
        },
        methods: {
            /*
             *  登出到登出畫面
             */
            logout() {
                //var routerName = this.$root.$data.store.getRouteName("public_home");
                //var routerName = this.$root.$data.store.getRouteName("login");
                const routerName = "public_home";
                this.$root.$data.store.logout();
                this.$router.push({ name: routerName })
            },
            /*
             *  建回到登入後畫面的首頁
             */
            getHomeRouterLink() {
                var routerName = this.$root.$data.store.getRouteName("loggedinHome");
                var result = { name: routerName }
                
                return result;
            },
            /*
             *  打開手機板的 menu
             */
            openMbMenu() {
                $("#mbmenu").animate({
                    top: '0px',
                    opacity: '1',
                });
            },
            /*
             *  關閉手機板的 menu
             */
            closeMbMenu() {
                $("#mbmenu").animate({
                    top: '-3000px',
                    opacity: '0',
                });
            },
            /*
             *  切換語言
             */
            changeLocale(newLocal) {
                this.$root.$data.store.changeLocale(newLocal);

                var result = { name: this.$route.name, params: { locale: i18n.locale }, query: this.$route.query }

                this.$router.push(result);
            },
            /*
             *  檢查登入的資料有沒有改變(可能在其他的 tab 登入)
             */
            checkSession() {
                let profile = this.$root.$data.store.getProfileFromLocalStorage();

                // 登入的資料改變後要重新 refresh page
                if (profile) {
                    if (profile['UserId'] != this.userId || profile['SelectedRole'] != this.selectedRole) {
                        window.location.reload();
                    }
                }
            }
        }
    }
</script>