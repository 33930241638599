<template>
    <div>
        <cmpMenu v-bind:nav="nav"></cmpMenu>
        <cmpLoginTokenTimer ref="myTimer"></cmpLoginTokenTimer>
        <div id="wapper">
            <hr id="tophr" />

            <div style="padding-bottom:40px">
                <router-view></router-view>
            </div>
            
        </div>

        <cmpFooter></cmpFooter>
    </div>
</template>

<script>
    import i18n from '@/i18n'

    import cmpMenu from '@/components/common/cmpMenu';
    import cmpFooter from '@/components/common/cmpFooter';
    import cmpLoginTokenTimer from '@/components/common/cmpLoginTokenTimer';

    import ApiService from '@/ApiService';

    export default {
        data() {
            return {
                loading: false,
                isLogin: false,
                nav: []
            };
        },
        components: {
            cmpMenu,
            cmpFooter,
            cmpLoginTokenTimer
        },
        async mounted() {
            await this.getAll();
            this.$refs.myTimer.setupTimer();
        },
        methods: {
            logout() {
                this.isLogin = false
            },
            async getAll() {
                const api = new ApiService('menu')
                var res = await api.getAll({ MenuType: 1, Language: i18n.locale }, 'nav')
                var x = this.buildNav(res)
                this.nav = x;
            },
            buildNav(menuItems) {
                var result = [];

                // Start with top level items
                for (let val of menuItems) {
                    if (val["MenuLevel"] == 1) {
                        var x = this.buildNavRecurs(menuItems, val)

                        result.push(x);
                    }
                }

                return result
            },
            buildNavRecurs(menuItems, curItem) {

                var curResult = {
                    "id": curItem["MenuId"],
                    "name": curItem["MenuDisplay"],
                    "url": curItem["ProgId"],
                    "level": curItem["MenuLevel"],
                    "children": []
                }

                // Get child menu items
                var childItems = menuItems.filter(t => t["MenuIndex"] == curItem["MenuId"]);

                // No child. Return empty children
                if (childItems.length == 0) {
                    return curResult
                }
                else {
                    for (let c of childItems) {
                        curResult["children"].push(this.buildNavRecurs(menuItems, c));
                    }
                    
                }

                return curResult;
                
            }
            
        },
        watch: {
            '$route.params': 'getAll'
        }
    }
</script>