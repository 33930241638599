<!-- footer 的 component -->
<template>
    <div v-bind:id="getCssClass('footer')">
        <div id="goto">
            <div v-bind:class="getCssClass('tooltip1')">
                <div v-bind:id="getCssClass('gotop')" v-on:click="scrollToTop()">▲</div>
                <span v-bind:class="getCssClass('tooltiptext')">{{ $t('component.tooltip.scrollToTop') }}</span>
            </div>

            <div v-bind:class="getCssClass('tooltip2')">
                <div v-bind:id="getCssClass('gobot')" v-on:click="scrollToBottom()">▼</div>
                <span v-bind:class="getCssClass('tooltiptext')">{{ $t('component.tooltip.scrollToBottom') }}</span>
            </div>

            <!--div id="gobg" class="login">✱</div-->
        </div>

    </div>
</template>

<script>
    import $ from 'jquery'

    export default {
        name: "cmpFooter",
        data() {
            return {

            };
        },
        props: {
            cssClass: { type: String, default: () => "" },  // CSS class 的主要名稱
        },
        methods: {
            /*
             *  頁面到最上方
             */
            scrollToTop() {
                $("html,body").animate({ scrollTop: 0 }, "slow");
                return false;
            },
            /*
             *  頁面到最下方
             */
            scrollToBottom() {
                $("html,body").animate({ scrollTop: document.documentElement.scrollHeight }, "slow");
                return false;
            },
            /*
             *  產生新的 css class 的名稱
             *  @param {string} className css class 的名稱
             */
            getCssClass(className) {
                if (this.cssClass) {
                    return `${className}_${this.cssClass}`
                }
                else {
                    return className;
                }
            }

        }
    }

</script>