
import axios from 'axios'

import router from './router'
//import Vue from 'vue'

export default class {
    constructor(objectName) {
        this.objectName = objectName
        this.client = axios.create({
            baseURL: window.vueRoot.$data.store.getApiEndpoint(),
            json: true
        })

        this.client.interceptors.request.use(function (config) {
            
            //const token = Vue.cookie.get("token")
            const token = window.vueRoot.$data.store.getLocalStorage("token");
            
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                delete config.headers["Authorization"];
                //delete axios.defaults.headers.common['Authorization']
            }

            return config;
        });

        this.client.interceptors.response.use(undefined, function (error) {
            
            if (error.response && error.response.status == 401) {
                //Vue.cookie.delete('token');

                // 登入失敗不要回到登入頁面
                if (error.request.responseURL.includes("api/authenticate/login")) {
                    return Promise.reject(error);
                }
                else {
                    var routerName = window.vueRoot.$data.store.getRouteName("public_home");
                    router.push({ name: routerName });
                }
            }
            else {
                if (error.response && error.response.data) {
                    //alert(error.response.data);
                    console.log(error.response.data);
                }
                else {
                    if (process.env.NODE_ENV == 'development') {
                        //alert(error.toString());
                        console.log(error.toString());
                    }
                    else {
                        //alert('系統錯誤');
                        //alert(error.toString());
                        console.log(error.toString());
                    }
                }
            }
            return Promise.reject(error);
        });
    }

    async execute(method, resource, data, headers) {
        
        if (method == "get") {
            return this.client({
                method,
                url: resource,
                params: data,
                headers: headers,
                timeout: 30000
            }).then(req => {
                return req.data
            })
        }
        else {
            return this.client({
                method,
                url: resource,
                data,
                headers: headers,
                timeout: 30000
            }).then(req => {
                return req.data
            }).catch(error => {
                // 處理錯誤
                if (error.response) {
                    // 請求已發，SERVER返回
                    console.log('Error status:', error.response.status);
                    console.log('Error response:', error.response.data);
                    alert(error.response.data);
                } else if (error.request) {
                    // 請求已發，SERVER未回
                    alert('No response received:', error.request);
                    console.log('No response received:', error.request);
                } else {
                    // 發送即出錯
                    alert('Error:', error.message);
                    console.log('Error:', error.message);
                }
                throw new Error('An error occurred during the request.');
            })
        }
    }
    getAll(data, subPath) {
        var requestData = data ? data : {};

        if (subPath) {
            return this.execute('get', `${this.objectName}/${subPath}`, requestData)
        }
        else {
            return this.execute('get', `${this.objectName}/`, requestData)
        }
    }
    get(id, language, subPath) {
        let requestData = { id: id, language: language };

        if (subPath) {
            return this.execute('get', `${this.objectName}/${subPath}/${id}`, requestData)
        }
        else {
            return this.execute('get', `${this.objectName}/single`, requestData)
        }
    }
    getCurrent(subPath) {
        if (subPath) {
            return this.execute('get', `${this.objectName}/${subPath}/current`, {})
        }
        else {
            return this.execute('get', `${this.objectName}/current`, {})
        }
    }
    create(data, subPath) {
        if (subPath) {
            return this.execute('post', `${this.objectName}/${subPath}`, JSON.stringify(data), { 'Content-Type': 'application/json' })
        }
        else {
            return this.execute('post', `${this.objectName}/`, JSON.stringify(data), { 'Content-Type': 'application/json' })
        }
    }
    update(id, data, subPath) {
        if (subPath) {
            return this.execute('put', `${this.objectName}/${subPath}/${id}`, data, {})
        }
        else {
            return this.execute('put', `${this.objectName}/${id}`, data, {})
        }
        
    }
    updateForm(id, data, subPath) {
        if (subPath) {
            return this.execute('put', `${this.objectName}/${subPath}/${id}`, data, { 'Content-Type': 'multipart/form-data' })
        }
        else {
            return this.execute('put', `${this.objectName}/${id}`, data, { 'Content-Type': 'multipart/form-data' })
        }

    }
    delete(id) {
        return this.execute('delete', `${this.objectName}/${id}`, {})
    }
    post(data, path) {
        return this.execute('post', `${this.objectName}/${path}`, JSON.stringify(data), { 'Content-Type': 'application/json' })
    }
    postForm(data, subPath) {
        if (subPath) {
            return this.execute('post', `${this.objectName}/${subPath}`, data, { 'Content-Type': 'multipart/form-data' })
        }
        else {
            return this.execute('post', `${this.objectName}`, data, { 'Content-Type': 'multipart/form-data' })
        }
        
    }
    exportExcel(data, subPath, filename = "export") {
        let path = "export";

        if (subPath) {
            path = subPath;
        }

        this.client({
            method: 'GET',
            url: `${this.objectName}/${path}`,
            params: data,
            responseType: 'blob',
        }).then(req => {
            var fileURL = window.URL.createObjectURL(new Blob([req.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${filename}.xlsx`);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove()
        });
    }
    uploadFile(formData) {
        return this.client({
            method: 'POST',
            url: `${this.objectName}/import`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function () {
            return true;
        }).catch(function () {
            return false;
        });
        
    }
    async login(data) {
        var res = await this.execute('post', `${this.objectName}/login`, JSON.stringify(data), { 'Content-Type': 'application/json' })
        
        //Vue.cookie.set('token', res.token);
        window.vueRoot.$data.store.setLocalStorage("token", res.token);
        window.vueRoot.$data.store.setLocalStorage("tokenExpiration", Date.parse(res.expiration));

        return res;
    }

    async renewToken() {
        var res = await this.execute('post', `${this.objectName}/renew`, {}, { 'Content-Type': 'application/json' })

        //Vue.cookie.set('token', res.token);
        window.vueRoot.$data.store.setLocalStorage("token", res.token);
        window.vueRoot.$data.store.setLocalStorage("tokenExpiration", Date.parse(res.expiration));

        return res;
    }

    async getSelectOptions(objectName, data, subPath) {
        if (subPath) {
            return await this.execute('get', `${objectName}/${subPath}`, data)
            
        }
        else {
            return await this.execute('get', `${objectName}/`, data)
        }
    }

    getProfile() {
        var res = this.execute('get', `${this.objectName}`)
        return res
    }
}