<!-- 手機板的 Navigation bar 的一個按鈕，用 recursion 的方式建立整個 navigation bar -->

<template>
    <li v-if="nav.children && nav.children.length > 0" @click.stop="clickLi($event)" style="cursor:pointer">
        {{ nav.name }}
        <ul v-if="nav.children && nav.children.length > 0" v-bind:class="getUlClass(nav.level)" style="display:none">
            <cmpNavItemMB v-for="child in nav.children" v-bind:nav="child" :key="child.id" @closeMenu="closeMenu"></cmpNavItemMB>
        </ul>
    </li>
    <li v-else @click="clickLeafLi()">
        <router-link v-if="nav.children.length == 0" :to="getNavUrl(nav.url)" :key="nav.id"><div style="width:100%">{{ nav.name }}</div></router-link>
    </li>
</template>


<script>
    import $ from 'jquery'
    import i18n from '@/i18n'

    function toggleList(L) {
        $(L).css('list-style-image', (!$(L).children().is(':hidden')) ? 'url(plus.gif)' : 'url(minus.gif)');
        $(L).children().toggle();
    }


    export default {
        name: "cmpNavItemMB",
        props: {
            nav: Object
        },
        methods: {
            /*
             *  建 <ul> 的 css class
             *  @param {number} level 這個 component 在 recursion 的層數
             */
            getUlClass(level) {
                if (level > 1)
                    return "submenu";

                return null;
            },
            /*
             *  按這個 nav 的 event
             *  @param {object} event 呼叫這個 event 的 html element
             */
            clickLi(event) {

                if ($(event.target).children().is(":hidden")) {
                    $(".menubox3").hide();
                    $(".submenu").hide();
                    if ($(event.target).children().attr("class") == "menubox2") {
                        $(".menubox2").hide();
                    }
                }
                toggleList(event.target);

            },
            /*
             *  如果這個 nav 是最後一層，按這個 nav 的 event
             */
            clickLeafLi() {
                this.$emit("closeMenu");
                $(".submenu").hide();
            },
            /*
             *  按關閉這個 menu 的 event
             */
            closeMenu() {
                this.$emit("closeMenu");
            },
            /*
             *  看要不要加語言別到這個 nav 的 URL，
             *  @param {string} url 這個 nav 的 URL
             */
            getNavUrl(url) {
                if (i18n.locale)
                    return `/${i18n.locale}${url}`;
                else
                    return url;
            }
        }
    };
</script>