
import Vue from 'vue'
import App from './App.vue'

import BootstrapVue from 'bootstrap-vue'

import '@/assets/css/bootstrap.css';
//import "bootstrap";
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/marqee.css';
import '@/assets/css/fs_style.css';
import '@/assets/css/common.css';
import '@/assets/css/public_style.css';

import '@/assets/js/bootstrap.bundle.min.js';
import ToggleButton from 'vue-js-toggle-button'

import router from './router'
import i18n from './i18n'
import VueApexCharts from 'vue-apexcharts'


import vueCustomElement from 'vue-custom-element'
import RockiotContainer from './components/rockiot.container.vue'
import RockiotAnimatedNumber from './components/rockiot.animated.number.vue'
import './assets/css/rockiot.ui.css'
import './assets/css/rockiot.chart.css'
import './plugins/rockiot.radial'

Vue.use(vueCustomElement)
Vue.customElement('rockiot-ui', RockiotContainer)
Vue.component('rockiot-animated-number', RockiotAnimatedNumber)
var VueCookie = require('vue-cookies');

Vue.use(BootstrapVue);
Vue.use(VueCookie);
Vue.use(VueApexCharts)
Vue.use(ToggleButton)

Vue.config.productionTip = false

import ApiService from '@/ApiService';

const siteSetting = {
    localhost: {
        //apiEndpoint: "http://localhost:50001/api/",
        socketEndpoint: "ws://ec2-52-198-65-186.ap-northeast-1.compute.amazonaws.com:5071"
    },
    dev : {
        //apiEndpoint: "http://ncuaiot-dev.ap-northeast-1.elasticbeanstalk.com/api/",
        socketEndpoint: "wss://socketserverdev.xraihealth.com"
    },
    master : {
        //apiEndpoint: "http://ncuaiot.ap-northeast-1.elasticbeanstalk.com/api/",
        socketEndpoint: "wss://socketservermaster.xraihealth.com"
    },
    "04189271" : {
        //apiEndpoint: "http://ncuaiot-dev.ap-northeast-1.elasticbeanstalk.com/api/",
        socketEndpoint: "wss://socketservermaster.xraihealth.com"
    }
};

// Manage application state
var store = {
    debug: true,
    socket: null,
    state: {
        profile: {},
        locale: ""
    },
    getRouteName(name, prefix, suffix) {
        var p = prefix ? prefix : '';
        var s = suffix ? suffix : '';

        return `${p}${name}${s}`;
    },
    setProfile(newValue) {
        localStorage.setItem('profile', JSON.stringify(newValue));
        this.state.profile = newValue
    },
    getProfile() {
        if (!this.state.profile || !this.state.profile.UserId) {
            this.state.profile = JSON.parse(localStorage.getItem('profile'));
        }
        return this.state.profile;
    },
    getProfileFromLocalStorage() {
        this.state.profile = JSON.parse(localStorage.getItem('profile'));
        
        return this.state.profile;
    },
    hasProfile() {
        if (localStorage.getItem('profile'))
            return true;

        return false;
    },
    setLocale(newValue) {
        localStorage.setItem('locale', JSON.stringify(newValue));
        this.state.locale = newValue;
        i18n.locale = newValue;
    },
    getLocale() {
        if (!this.state.locale) {
            this.state.locale = JSON.parse(localStorage.getItem('locale'));
        }
        return this.state.locale;
    },
    hasRole(roleName) {
        const p = this.getProfile();

        if (p['Roles']) {
            var theRole = p.Roles.filter(t => t.Name == roleName);

            return theRole.length > 0;
        }

        return false;
    },
    getPrivilege(objectName) {
        if (!this.state.profile['Privileges']) {
            this.state.profile = JSON.parse(localStorage.getItem('profile'));
        }
        var p = JSON.parse(localStorage.getItem('profile'));
        return p['Privileges'].filter(t => t.ObjectName == objectName);
    },
    hasPrivilege(objectName, privilegeType) {
        try {
            const p = this.getPrivilege(objectName);

            if (p.length > 0 && p[0][privilegeType])
                return true;

            return false;
        }
        catch {
            return false;
        }
    },
    getSelectedRole() {
        const p = this.getProfile();
        return p['SelectedRole'];
    },
    getIotType() {
        const p = this.getProfile();
        return p['IotType'];
    },
    async logout() {
        localStorage.clear();
        this.state.profile = {};

        Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))

        const apiAuth = new ApiService('authenticate');
        await apiAuth.getAll({}, "logout");
    },
    setLocalStorage(key, value) {
        localStorage.setItem(key, value);
    },
    getLocalStorage(key) {
        return localStorage.getItem(key);
    },
    deleteLocalStorage(key) {
        localStorage.removeItem(key);
    },
    setSessionStorage(key, value) {
        sessionStorage.setItem(key, value);
    },
    getSessionStorage(key) {
        return sessionStorage.getItem(key);
    },
    deleteSessionStorage(key) {
        sessionStorage.removeItem(key);
    },
    setSiteId(siteId) {
        this.setLocalStorage("siteId", siteId);
    },
    async getSiteId() {        
        let siteId = this.getSessionStorage("siteId");
        if (!siteId) {
            const apiPublic = new ApiService('public');
            let response = await apiPublic.getAll(null, "siteid");
            siteId = response.SiteId
            this.setSiteId(siteId);
        }

        return siteId
        
    },
    async getImagePath() {
        let siteId = await this.getSiteId();
        return `assets/images/${siteId}`;
    },
    changeLocale(newLocale) {
        if (newLocale) {
            this.setLocale(newLocale);
        } else {
            let currentLocale = i18n.locale;

            if (currentLocale === "en-us")
                this.setLocale("zh-tw");
            else
                this.setLocale("en-us");
        }
    },
    getApiEndpoint() {
        return `${location.origin}/api/`;
    },
    async getSocketEndpoint() {
        let siteId = await this.getSiteId();
        return siteSetting[siteId].socketEndpoint;
    }

}

window.vueRoot = new Vue({
    data: {
        store: store
    },

    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
